<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Groups</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                      
              </CCol>
              <!--CCol md="2">
                   <b-button variant="primary" to="/admin/create" >New Admin</b-button>                    
              </CCol-->             
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

          <div class="table-responsive">
          <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">

          <!--template v-slot:cell(roles)="{ item }">                
              <div v-for="(value, key, index) in item.roles">
                  {{ value.role }}
              </div>
          </template-->

          <template v-slot:cell(createDate)="{ item }"> 
                {{ item.createDate | dateWithTime }}
          </template>

         <template v-slot:cell(actions)="{ item }">
            <b-button variant="info" :to='"/groupadmin/viewsroles/" + item.name' size="md" >View Roles</b-button>        
         </template>


        </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>



          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import AdminService from '@/api/AdminService.js';
import moment from 'moment';

 export default {
    name: "users",
    data() { var date = new Date();
      return {        
        items:[],             
        fields: [   
          {
            name : 'name',
            key: 'name',
            label : 'Group Name',
            sortable: true
          },  
          {
            key: 'description',
            label : 'Description',
            sortable: true
          },                  
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },   
          {
            key: 'lastUpdate',
            label : 'Last Updated',
            sortable: true
          }, 
          {            
            key: 'actions',
            label: 'Actions'         
          }             
          
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


      },

    created () {           
       
      this.isSuperAdmin(); 
      this.getUserGroupList();

    },
    methods: {  

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },     
      
      getUserGroupList: function() {
        AdminService.getUserGroupList().then(resp => {
           this.items = resp.payload;          
        }, error => {
          this.loading = false;
        });
      }


    }    
 }; 

</script>